import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import api from '../../../../api/index'

const createApp = async (appName) => {
  const emailAddress = localStorage.getItem('emailAddress')

  appName = processAppName(appName)

  try {
    if (isInvalidAppName(appName)) {
      throw new TypeError('invalid appname')
    }

    if (emailAddress === null) {
      throw new TypeError(`Invalid emailAddress`)
    }

    await api.createApp({ appName, userAccount: emailAddress })
  } catch (e) {
    console.error('error creating app', e)
    // setLoading(false)
    return
  }
}

function isInvalidAppName (appName) {
  return (appName == null || appName.startsWith(' ') || appName.endsWith(' ') || appName === '')
}

function processAppName (appName) {
  return appName.trim()
}



class FormDialog extends React.Component {
  constructor (props) {
    super(props)
    this.handleClose = this._handleClose.bind(this)
    this.state = {
      term: '',
      error: false,
      open: false
    }
  }

  _handleClose () {
    this.setState({ open: false })
    window.location.reload(true)
  }

  onInputChange = (event) => {
    this.setState({ term: event.target.value })
  }

  render () {
    const actions = [
      <DialogActions key="1">
        <Button onClick={this.handleClose} color='primary'> Cancel </Button>
        <Button type='submit' color='primary'> Submit </Button>
      </DialogActions>
    ]

    const handleClickOpen = () => {
      this.setState({ open: true })
    }

    return (
      <div>
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={handleClickOpen}
        >
          + Create App
        </Button>
        <Dialog
          aria-labelledby='form-dialog-title'
          modal="true"
          open={this.state.open}
        >
          <DialogTitle id='form-dialog-title'>New Application</DialogTitle>
          <DialogContent>
            <form onSubmit={async (e) => { e.preventDefault(); await createApp(this.state.term); this.handleClose(); }}>
              <DialogContentText>
                Create a new application to use Voxer's SDK
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Application Name'
                type='text'
                value={this.state.term}
                onChange={this.onInputChange}
                fullWidth
              />
              {actions}
            </form>
          </DialogContent>
        </Dialog>
      </div>
    )
  };
}

export default FormDialog
