import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

import api from '../../api/index'

// components
import PageTitle from '../../components/PageTitle'
import AppTable from './components/Table/AppTable'
import Alert from '@material-ui/lab/Alert'
// import mock from './mock'


export default function Home (props) {
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const emailAddress = localStorage.getItem('emailAddress')

  useEffect(() => {
    api.getAppsForUser({ userId: emailAddress })
      .then((response) => {
        if (response && response.data) {
          setApps(response.data)
        }
        setLoading(false)
        setError(false)
      }).catch((e) => {
        setLoading(false)
        setError(true)
      })
  }, [])

  return (
    <>
      <PageTitle title='Create New Application' button='+ Create App' />
      <h3 style={{ color: '#B9B9B9' }}>Use the Voxer SDK and integrate real-time communication into your application or service.</h3>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {loading && (<CircularProgress size={26} />)}
            {error ? (<Alert severity="error">Unable to get the list of Apps</Alert>)
              : <AppTable data={ apps }/>
            }
          </Grid>
        </Grid>
      </div>
    </>
  )
};
