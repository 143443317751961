import React, { useState, useEffect } from 'react'
import {
  Grid,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Typography,
  FormControl,
  Paper,
  FormLabel, Button, Card, CardContent } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import api from '../../api/index'

// styles
import useStyles from './styles'
// components
import PageTitle from '../../components/PageTitle'

export default function Settings(props) {
  const [value, setValue] = useState('none')
  const [appId, setAppId] = useState(props.match.params.appId)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    api.getSettings({ appId: props.match.params.appId })
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          setValue(response.data[0].config_value)
        }
      })
      .catch((e) => {
        setError(true)
      })
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true)
    api.setSettings({
        appId: appId,
        settings: [{ "config_name": "walkie_talkie_feature_setting",  "config_value": value }]
      }).then((response) => {
        setIsLoading(false)
        if (response && response.status === 200) {
          setSuccess(true)
          setError(false)
        } else {
          setError(true)
          setSuccess(false)
        }
      }).catch((e) => {
        setIsLoading(false)
        setError(true)
        setSuccess(false)
      })
  }

  return (
    <>
      <Container className={classes.container}>
        <PageTitle title='Settings' />
        <Paper
          style={{ marginTop: "4px" }}
        >
          <Grid container spacing={1} item xs={12}>
            <Grid item xs={12} style={{ color: '#696969' }}>
                <Card variant="outlined" style={{ borderTop: "none", borderRight: "none", boxShadow: "none" }}>
                  <CardContent>
                    <Typography variant='h5' style={{ fontWeight: "bold" }} > App ID </Typography>
                    <Typography variant='h6'> {appId} </Typography>
                  </CardContent>
                </Card>
            </Grid>
            { (error || success) &&
              <Grid item xs={12}>
                  <Card variant="outlined" style={{ border: "none", boxShadow: "none" }}>
                    <CardContent>
                      <Grid item xs={12}>
                        {error && (<Alert severity="error">Something went wrong !!</Alert>)}
                        {success && (<Alert severity="success">Settings updated successfully!!</Alert>)}
                      </Grid>
                    </CardContent>
                  </Card>
              </Grid>
            }
            <Grid item xs={12}>
                <Card variant="outlined"  style={{ color: '#CCCCCC', border: "none", boxShadow: "none" }}>
                  <CardContent>
                      <Typography variant='h5' style={{ paddingBottom: '10px' }}>
                        Walkie Talkie Mode
                      </Typography>
                      <Typography variant='h6'>
                        Set the walkie talkie mode for this user id. This setting will be applied to every chat
                      </Typography>
                  </CardContent>
                </Card>
            </Grid>

            <Grid item xs={6}>
              <Card variant="outlined" style={{ border: "none", boxShadow: "none" }}>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                      <Grid item xs={12} style={{ color: '#696969' }}>
                        <FormControl>
                          <FormLabel id="walkie_talkie_mode"></FormLabel>
                            <RadioGroup
                              aria-label="walkie_talkie_mode"
                              name="walkie_talkie_mode"
                              value={value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="none"
                                control={<Radio color="primary"/>}
                                label={<div style={{marginTop: '15px'}}>
                                        <Typography style={{fontWeight: 'bold'}}>Manage on mobile clients</Typography>
                                        <Typography >Users on mobile devices control walkie-talkie mode</Typography>
                                      </div>
                                      }
                                style={{ paddingBottom: '25px' }}
                              />
                              <FormControlLabel
                                value="playback"
                                control={<Radio color="primary"/>}
                                label={<div style={{marginTop: '15px'}}>
                                        <Typography style={{fontWeight: 'bold'}}>Managed Centrally</Typography>
                                        <Typography >Walkie-Talkie mode is turned on for all chats by default</Typography>
                                      </div>
                                      }
                                style={{ paddingBottom: '25px' }}
                              />
                              <FormControlLabel
                                value="disabled"
                                control={<Radio color="primary"/>}
                                label={<div style={{marginTop: '15px'}}>
                                        <Typography style={{fontWeight: 'bold'}}>Disabled</Typography>
                                        <Typography >Walkie-Talkie mode is not available</Typography>
                                      </div>
                                      }
                                style={{ paddingBottom: '25px' }}
                              />
                            </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: "center"}}>
                        {isLoading ? (
                                  <CircularProgress size={26} />
                                ) : <Button variant="contained" color="primary" type="submit" >Validate</Button>
                        }
                      </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  )
}