import React from 'react'

// styles
import useStyles from './styles'

// components
import { Typography } from '../Wrappers'
import FormDialog from '../../pages/dashboard/components/Modal/FormDialog.js'

export default function PageTitle (props) {
  var classes = useStyles()

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant='h1' size='sm'>
        {props.title}
      </Typography>
      {props.button && (
        <FormDialog
          classes={{ root: classes.button }}
          variant='contained'
          size='large'
          color='primary'
        />
      )}
    </div>
  )
}
