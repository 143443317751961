export default {
  android: [
    {
      id: 0,
      version: 'v1.6 BETA',
      description: 'Integrate encrypted live audio into your Kotlin based application',
      sdk: 'Download SDK',
      app: 'Download Sample App',
      platform: 'android'
    }
  ],
  iOS: [
    {
      id: 0,
      version: 'v0.17 BETA',
      description: 'Integrate encrypted live audio into your Swift based application',
      sdk: 'Download SDK',
      app: 'Download Sample App',
      platform: 'ios'
    }
  ],
  appinfo: [
    {
      objectId: 0,
      name: 'apps',
      id: 'apps',
      secret: 'apps',
    }
  ]
}
