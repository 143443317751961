import React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, 
  Paper, 
  Typography, 
  Button,
  TextField,
  Fade,
  CircularProgress,
} from '@material-ui/core'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import api from '../../api/index'
import crypto from 'crypto'
  

// styles
import useStyles from './styles'

// logo
import logo from '../login/logo.svg'



export default function Forgot (props) {
  var classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [passwordSent, setPasswordSent] = useState(false)
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  })

  const values = queryString.parse(props.location.search)

  const sendPassword = event => {
    if (passwordValue !== confirmPasswordValue) {
      alert("Passwords do not match")
    } else {
      api
        .resetPassword({
          token: values.token,
          emailAddress: values.emailAddress,
          new_password: hash(passwordValue)
        })
        .then(response => {
          if (response.status !== 200) {
            console.error(response)
          }
        })
        .catch(error => {
          console.error('error', error)
        })
    }
  }

  function hash (new_password) {
    const hash = crypto.createHash('sha256')
    return hash.update(new_password).digest('hex')
  }

  const onSubmitForgot = (data) => {
    setPasswordSent(true);
  }

  

  const emailValidatorPattern = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/ // eslint-disable-line

  return (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
          <img className={classes.logotypeIcon && classes.logotype} src={logo} alt='logo' />
          {passwordSent ? (
            <>
            <Typography variant='h3' className={classes.greeting}>
              Your password has been reset.
            </Typography>
            <p style={{ textAlign: 'center' }}>If an account was found, a reset password link will be sent to your email. Please check your spam folder for the email.</p>
            <Link
              className={classes.link}
              to='/login'
            >Back to Dashboard
          </Link>
            </>
          ) : (
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <Typography variant='h3' className={classes.greeting}>
                Reset Password
              </Typography>
              <Fade in={error}>
                <Typography 
                  color='secondary' 
                  className={classes.errorMessage}
                >
                  Something is wrong with your password :(
                </Typography>
              </Fade>

              <form onSubmit={handleSubmit(onSubmitForgot)}>
              <TextField
                  id='password'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={passwordValue}
                  onChange={e => { setPasswordValue(e.target.value) }}
                  margin='normal'
                  placeholder='New Password'
                  type='password'
                  fullWidth
                  helperText={errors.password && 'Password must be 8 characters'}
                  name='password'
                  inputRef={register({ required: true, minLength: 8 })}
                />
                <TextField
                  id='password'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={confirmPasswordValue}
                  onChange={e => { setConfirmPasswordValue(e.target.value) }}
                  margin='normal'
                  placeholder='Confirm Password'
                  type='password'
                  fullWidth
                  helperText={errors.confirmPassword && 'The passwords do not match'}
                  name='confirmPassword'
                  inputRef={register({ 
                    required: true, 
                    minLength: 8,
                    validate: value =>
                      value === passwordValue
                  })}
                />
                <div className={classes.form}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={errors.password || errors.confirmPassword || passwordValue.length === 0 || confirmPasswordValue.length === 0 }
                      onClick={() => {
                        sendPassword(
                          passwordValue,
                          // props.history,
                          setIsLoading,
                          setError
                        )
                      }}
                      size='large'
                      variant='contained'
                      color='primary'
                      type='submit'
                      fullWidth
                      className={classes.createAccountButton}
                    >
                    Reset Password
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
          )}
      </Paper>
    </Grid>
  )
}
