import React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, 
  Paper, 
  Typography, 
  Button,
  TextField,
  Fade,
  CircularProgress,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import api from '../../api/index'
  

// styles
import useStyles from './styles'

// logo
import logo from '../login/logo.svg'



export default function Forgot (props) {
  var classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  })


  const sendEmail = event => {
    api
    .forgotPassword({
      emailAddress: emailValue
    })
    .then(response => {
      //TODO send email?
    })
    .catch(error => {
      console.log(error.data);
    })
  }

  const onSubmitForgot = (data) => {
    setEmailSent(true);
  }

  

  const emailValidatorPattern = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/ // eslint-disable-line

  return (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
          <img className={classes.logotypeIcon && classes.logotype} src={logo} alt='logo' />
          {emailSent ? (
            <>
            <Typography variant='h3' className={classes.greeting}>
              Please check your email.
            </Typography>
            <p style={{ textAlign: 'center' }}>If an account was found, a reset password link will be sent to your email. Please check your spam folder for the email.</p>
            </>
          ) : (
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <Typography variant='h3' className={classes.greeting}>
                Forgot Password?
              </Typography>
              <Fade in={error}>
                <Typography 
                  color='secondary' 
                  className={classes.errorMessage}
                >
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>

              <form onSubmit={handleSubmit(onSubmitForgot)}>
                <TextField
                  id='email'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={emailValue}
                  onChange={e => { setEmailValue(e.target.value) }}
                  margin='normal'
                  placeholder='Email Address'
                  type='email'
                  fullWidth
                  helperText={errors.email && 'Please provide a valid email'}
                  name='email'
                  inputRef={register({ pattern: emailValidatorPattern })}
                />
                <div className={classes.form}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={errors.email || emailValue.length === 0 }
                      onClick={() => {
                        sendEmail(
                          emailValue,
                          props.history,
                          setIsLoading,
                          setError
                        )
                      }}
                      size='large'
                      variant='contained'
                      color='primary'
                      type='submit'
                      fullWidth
                      className={classes.createAccountButton}
                    >
                    Send Link
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
          )}
          <Link
            className={classes.link}
            to='/login'
          >Back to Login
          </Link>
      </Paper>
    </Grid>
  )
}
