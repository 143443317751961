import React from 'react'
import { Grid, Container } from '@material-ui/core'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

// components
import PageTitle from '../../components/PageTitle'
import Widget from '../../components/Widget'

export default function iOSDocumentation () {

  return (
    <Container>
      <PageTitle title='iOS Documentation' />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget>
            <h1>Voxer iOS SDK v0.17</h1>

            <h2>Requirements</h2>
              <ul>
                <li>A deployment target of iOS 11 and above is required</li>
                <li>Xcode 12.5</li>
              </ul>

            <h2>Features</h2>
              <ol>
                <li key={1}>Every client will be provided with unique appId and appSecret. These two values will be needed to initialize voxer sdk in their apps.</li><br />
                <li key={2}>Any user can login using a unique user id. Users can the start chat with any other user as long they know the user id of the other user.</li><br />
                <li key={3}>Users can send audio and text messages.</li><br />
                <li key={4}>Users can receive live audio messages, pre-recorded audio messages and text messages.</li><br />
                <li key={5}><b>Partial Upload and Download Feature</b> Even if there are multiple network disconnections while recording/receiving an audio message, the message will be correctly delivered/received.</li><br />
                <li key={6}><b>Send/Receive long audio messages</b> Users can send/receive long audio messages while the user is in online or offline mode. Voxer SDK's optimization minimizes the data uploaded to the backend cloud service and the data downloaded by the client.</li><br/>
                <li key={7}><b>Walkie-Talkie Play Feature</b> After the user starts the chat with any user by explicitly calling start chat, any incoming live audio message will be played out loud regardless of whether app is in background or foreground. SDK will continue to play live audio outloud from there on until user explicitly calls logout.</li><br />
                <li key={8}><b>Walkie-Talkie Record Feature</b> Users can record an audio to a chat using an external button. User has to enable the walkie talkie record mode for the chat to enable this feature. At any given time only one chat can be enabled for walkie talkie record. If user enables the walkie talkie record mode for a chat while some other chat is already enabled for walkie talkie record, sdk overrides the older selection with the latest selection.
</li><br />
                <li key={9}><b>Audio Focus</b> Sdk respects audio focus rules of the ecosystem. It stops audio playback if user picks up a call. It lowers the volume upon loosing audio focus for brief period of time and restores the audio playback volume upon regaining the focus. Sdk only starts audio recording if no phone call is in progress.</li><br />
                <li key={10}><b>Audio playback routing</b> Sdk can route audio through bluetooth headset(supports HFP or A2DP profile), wired headset, earpiece or phone speaker.</li><br/>
                <li key={11}><b>Audio mic routing</b> Sdk can record audio from phone mic, wired headset or bluetooth headset. Sdk automically switch to wired headset or bluetooth headset if any one of them is connected. Once the user disconnects external mic, the sdk defaults to phone mic.</li><br/>
                <li key={12}><b>Group Chat</b> Users can start a group chat with one or more users. The chat creator is assigned the admin role and can rename the chat, add members, remove members and reassign the admin of the chat. All the members of the chat can view the list of members in that chat. Users can view the sender name and the timestamp of every incoming message. All the members can leave the chat, except the admin.</li><br/>
                <li key={13}><b>Admin setting</b> Admin users can manage the walkie-talkie feature from the developer portal. They can decide if the walkie-talkie feature is managed on mobile clients, centrally or disabled. The setting is applied to every chat of the application.</li><br/>
              </ol>

            <h2>VoxerKitDemo App</h2>
            <p>To run the VoxerKitDemo, simply open "VoxerKitDemo.xcworkspace" with Xcode and select "VoxerKitDemo" target to run it on the simulator.</p>
            
            <h2>Dependencies</h2>
            <p>The Voxer SDK has the following dependencies. Please add them using CocoaPods.</p>
            <ul>
              <li>'KeychainAccess', '4.2.2'</li>
              <li>'ReachabilitySwift', '5.0.0'</li>
              <li>'OpenGraph', '1.3.0'</li>
              <li>'SwiftyBeaver', '1.9.5'</li>
            </ul>

            <p>Add the following snippet at the end of your Podfile before running pod install.</p>
            <pre><SyntaxHighlighter>
{`post_install do |installer|
  installer.pods_project.build_configurations.each do |config|
    config.build_settings["EXCLUDED_ARCHS[sdk=iphonesimulator*]"] = "arm64"
    config.build_settings["BUILD_LIBRARY_FOR_DISTRIBUTION"] = "YES"
  end
end`}
            </SyntaxHighlighter></pre>

            <h2>Xcode Setup</h2>
            <p>The VoxerKit framework is a XCFramework.</p>

            <ol>
              <li>Download the VoxerKit.xcframework</li>
              <li>Add the VoxerKit.xcramework to your Xcode project. </li>
              <li>Under your project "General -> Targets -> Frameworks, Libraries, and Embedded Content", make sure VoxerKit.xcframework is on the list and "Embed & Sign" is selected.</li>
              <li>Go to your project "Build Settings" -> "Excluded Architectures" -> Add "Any iOS Simulator SDK" with the value "arm64" (for Debug And Release)</li>
              <li>In "Signing & Capabilities" add "Background Modes" and then select "Audio, AirPlay, and Picture in Picture" and "Voice over IP"</li>
            </ol>

            <h2>Programming Guide</h2>
            <h2>Initialize Voxer SDK</h2>
            <pre><SyntaxHighlighter>let voxer: Voxer = Voxer(isBackgroundAudioEnable: true)</SyntaxHighlighter></pre>

            <h2>Authentication</h2>
            <h4>Log into the Voxer network</h4>
            <pre><SyntaxHighlighter>
{`voxer.loginWith(username: username, password: password) { [weak self] response in
    if response == .success {
      print ("Login suceeded")
    } else {
      print ("Login failed")
    }
}`}
              </SyntaxHighlighter></pre>

            <h4>Check If a User is Already Logged In</h4>
            <pre><SyntaxHighlighter>
{`if voxer.loggedInUsername?.isEmpty == false {
    // User already logged from previous session. 
}`}
            </SyntaxHighlighter></pre>

            <h2>Log out of the Voxer network</h2>
            <p>When this logout method is called, it destroys the local database. That means all the messages are deleted from the local device.</p>
            <SyntaxHighlighter>voxer.logout()</SyntaxHighlighter>

            <h2>Start a chat with a group of users</h2>
            <pre><SyntaxHighlighter>
{`voxer.startChat(with: ["Bob", "Charlie"]) { chat in
    guard let chat = chat else { return }
    print("Successfully started a chat.")
}`}
            </SyntaxHighlighter></pre>

            <h2>Send a text message</h2>
            <SyntaxHighlighter>chat.send(text:"Hello Bob!")</SyntaxHighlighter>

            <h2>Send an audio message on voxer network</h2>
            <h4>Start recording an audio message</h4>
            <SyntaxHighlighter>
{`chat.startRecording(onRecordingStarted: {
    print("Started recording")
}, onRecordingError: { error in
    print("ERROR: Could not start recording: \(error)")
})`}
            </SyntaxHighlighter>

            <h4>End recording an audio message</h4>
            <SyntaxHighlighter>
{`chat.stopRecording {
    print("Stopped recording")
}`}
            </SyntaxHighlighter>

            <h2>Walkie Talkie Modes</h2>
            <p>You can enable a chat with either walkie talkie 'play' or 'play and record' modes.</p>
            <p>With the 'play' mode, any incoming live audio message will be played out loud regardless of whether app is in background or foreground.</p>
            <p>The 'play and record' mode includes the 'play' mode functionality and additionally, it allows your app to record and send audio messages any time whether your app is in the foreground or "running" in the background. You can set the 'play and record' mode to only one chat at a time.</p>
            <p>Notes: if your app is suspended in the background,  walkie talkie 'play' and 'play and record' modes do not work.</p>

            <h4>Enable Walkie Talkie</h4>
            <SyntaxHighlighter>
{`if voxer.setWalkieTalkie(for: chat, mode: .playAndRecord) != true {
    print("ERROR: Could not enable walkie talkie for chat id: \(chat.id)")
}`}
            </SyntaxHighlighter>

            <h4>Disable Walkie Talkie</h4>
            <SyntaxHighlighter>
{`if voxer.setWalkieTalkie(for: chat, mode: .none) != true {
    print("ERROR: Could not disable walkie talkie for chat id: \(chat.id)")
}`}
            </SyntaxHighlighter>

            <h4>Get the current walkie talkie record chat id</h4>
            <SyntaxHighlighter>let chatId = voxer.getWalkieTalkieRecordChatId()</SyntaxHighlighter>

            <h4>Get the list of current walkie talkie play chat ids</h4>
            <SyntaxHighlighter>let chatIds = voxer.getWalkieTalkiePlayChatIds()</SyntaxHighlighter>

            <h4>Talk with Walkie Talkie Record Mode</h4>
            <p>The first call to this method starts recording the audio and sends it. The second call to this method will stop the audio recording.</p>
            <SyntaxHighlighter>
{`let toggleSuccess = voxer.toggleWalkieTalkieRecord {
	  print("Started walkie talkie recording")
} onRecordingEnded: {
	  print("Stopped walkie talkie recording")
} onRecordingError: { error in
    print("ERROR: Recording failed for walkie talkie.")
}`}
            </SyntaxHighlighter>

            <h4>Get admin setting for walkie-talkie feature</h4>
            <p>You can check what the admin setting is for walkie-talkie feature and show the corresponding UI to your users.</p>
            <SyntaxHighlighter>
{`if voxer.getWalkieTalkieFeatureAdminSetting() == WalkieTalkieFeatureAdminSetting.none {
    // Users on mobile devices control walkie-talkie mode
} else if voxer.getWalkieTalkieFeatureAdminSetting() == WalkieTalkieFeatureAdminSetting.playback {
    // Walkie-talkie mode is turned on for all chats by default
} else if voxer.getWalkieTalkieFeatureAdminSetting() == WalkieTalkieFeatureAdminSetting.disabled {
    // Walkie-talkie mode is not available
}`}
            </SyntaxHighlighter>

            <h2>Message Management</h2>
            <p>iOS VoxerKit uses Core Data to store and manage message updates</p>

            <h4>Message Status</h4>
            <p>You can check the message status using `Message.state` and show the corresponding UI to your users. </p>

            <h4>Resend a failed message</h4>
            <p>If a message failed to send, you can use this method to resend it:</p>
            <SyntaxHighlighter>
{`chat.resend(message:message) {
    print("Message has been successfully resent.")
} onError: {
    print("ERROR: Message could not be resent.")
}`}
            </SyntaxHighlighter>
            
            <h4>Fetch all messages</h4>
            <p>This code snippet assuming you are using a UITableView.</p>
            <SyntaxHighlighter>
{`let messageFRC = self.chat?.getMessageFetchedResultsController()
messageFRC.delegate = self
do {
    try self.messageFRC.performFetch()
} catch let error as NSError {
    print("Failed to fetch messages: " + error.localizedDescription)
}

func tableView(_ tableView: UITableView, cellForRowAt indexPath: IndexPath) -> UITableViewCell {
    let message = self.messageFRC.object(at: indexPath)
}`}
            </SyntaxHighlighter>

            <h4>Listen to message changes in the chat</h4>
            <p>You will need to implement <b>NSFetchedResultsControllerDelegate</b>:</p>
            <SyntaxHighlighter>
{`func controllerWillChangeContent(_ controller: NSFetchedResultsController<NSFetchRequestResult>) {
    self.tableView.beginUpdates()
}

func controllerDidChangeContent(_ controller: NSFetchedResultsController<NSFetchRequestResult>) {
    self.tableView.endUpdates()
}

func controller(_ controller: NSFetchedResultsController<NSFetchRequestResult>, didChange anObject: Any, at indexPath: IndexPath?, for type: NSFetchedResultsChangeType, newIndexPath: IndexPath?) {
    guard let index = indexPath ?? (newIndexPath ?? nil) else { return }
      
    switch type {
      case .insert:
        guard let message = anObject as? Message else { return }
        tableView.insertRows(at: [index], with: .top)
        if message.contentType == .audio {
          self.newMessageIndex = index
        }
      default:
        // Handle other cases as needed.
        return
    }
}`}
            </SyntaxHighlighter>

            <h4>Manage partially downloaded messages</h4>
            <p>If the sender/receiver disconnects while a message is played, the message status changes to partiallyDownloaded. Upon sender/receiver reconnection, it then changes to readyForDownload. By listening to message changes as detailed above and using the Playable protocol, you can play the message and the download will be resumed automatically.</p>
            <SyntaxHighlighter>
{`func controller(_ controller: NSFetchedResultsController<NSFetchRequestResult>, didChange anObject: Any, at indexPath: IndexPath?, for type: NSFetchedResultsChangeType, newIndexPath: IndexPath?) {
    guard let index = indexPath ?? (newIndexPath ?? nil) else { return }
      
    switch type {
      case .update:
        guard let message = anObject as? Message, let cell = tableView.cellForRow(at: index) as? AudioCell else { return }
        if message.state == .readyForDownload {
          cell.playable?.play()
        }
      default:
        // Handle other cases as needed.
        return
    }
}`}
            </SyntaxHighlighter>

            <h2>Chat Management</h2>

            <h4>Get the list of chats</h4>
            <SyntaxHighlighter>
{`do {
      let chats = try voxer.getChatList()
      // Update UI
} catch {
      print("Failed to fetch chat list")
}`}
            </SyntaxHighlighter>

            <h4>Observe the list of chats</h4>
            <SyntaxHighlighter>
{`// Add observer
voxer.addChatListObserver(self, selector: #selector(ChatListViewController.chatListDidChange(notification:)))
// Handle notification
@objc func chatListDidChange(notification: NSNotification) {
    guard let chats = notification.userInfo?["chats"] as? [Chat] else { return }
    self.chats = chats
    // Update UI
}`}
            </SyntaxHighlighter>

            <h4>Get the list of members in a chat</h4>
            <SyntaxHighlighter>
{`chat.getMembers { members in
    guard let members = members else { return }
    // Update UI
}`}
            </SyntaxHighlighter>

            <h4>Rename a chat</h4>
            <SyntaxHighlighter>
{`chat.rename(name: name) { name in
    print("Chat has been successfully renamed to \(name).")
    // Update UI
} onError: { name in
    print("ERROR: Chat could not be renamed to \(name).")
}`}
            </SyntaxHighlighter>

            <h4>Add a member to a chat</h4>
            <SyntaxHighlighter>
{`chat.addMember(userId: "Dave") { userId in
    print("Member \(userId) has been added successfully to the chat.")
    // Update UI
} onError: { userId in
    print("ERROR: Member \(userId) could not be added to the chat.")
}`}
            </SyntaxHighlighter>

            <h4>Remove a member from a chat</h4>
            <SyntaxHighlighter>
{`chat.removeMember(userId: "Dave") { userId in
    print("Member \(userId) has been removed successfully from the chat.")
    // Update UI
} onError: { userId in
    print("ERROR: Member \(userId) could not be removed from the chat.")
}`}
            </SyntaxHighlighter>

            <h4>Reassign the admin of a chat</h4>
            <SyntaxHighlighter>
{`chat.reassignAdmin(userId: "Dave") { userId in
    print("Member \(userId) is the new admin.")
    // Update UI
} onError: { userId in
    print("ERROR: Member \(userId) could not be set as the new admin.")
}`}
            </SyntaxHighlighter>

            <h4>Leave a chat</h4>
            <SyntaxHighlighter>
{`chat.leaveChat {
    print("You have successfully left the chat.")
    // Update UI
} onError: {
    print("ERROR: You could not leave the chat.")
}`}
            </SyntaxHighlighter>

            <h2>APIs Documentation</h2>
            <p>Description of the APIs showcased above can be found&nbsp;
              <a
                href='https://developers.voxer.io/ios/docs'
                target='_blank'
              >here
              </a>
              .
            </p>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  )
}
