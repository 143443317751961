import React from 'react'
import api from '../../../../api/index'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@material-ui/core'

// components
import { Button } from '../../../../components/Wrappers'

// const states = {
//   sent: 'success',
//   pending: 'warning',
//   declined: 'secondary'
// }

export default function TableComponent ({ data }) {
  if (data.length > 0) {
    var keys = Object.keys(data[0]).map(i => i.toUpperCase())
    keys.shift() // delete "id" key
    keys.pop() // delete "platform" key
  }

  const downloadSDKiOS = async event => {
    return downloadSDK('ios')
  }

  const downloadSDKAndroidNoOp = {}

  const downloadSDK = async platform => {
    await api
      .downloadSdk(platform)
      .then(res => {
        if (res == null) {
          throw new Error('Response is null')
        }
        // window.alert(`SDK downloaded successfully`)
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        if (platform === 'android') {
          link.setAttribute('download', 'voxersdk.aar')
        } else {
          link.setAttribute('download', 'VoxerKit.xcframework.zip')
        }
        document.body.appendChild(link)
        link.click()
      })
      .catch(e => {
        console.error(e)
        // TODO: show UI alert
      })
  }

  const downloadSampleAppiOS = async event => {
    return downloadSampleApp('ios')
  }

  const downloadSampleAppAndroid = async event => {
    return downloadSampleApp('android')
  }

  const downloadSampleApp = async platform => {
    await api
      .downloadSampleApp(platform)
      .then(res => {
        if (res == null) {
          console.error('Response is null')
          throw new Error('Response is null')
        }
        // window.alert(`Sample App downloaded successfully`)
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        if (platform === 'android') {
          link.setAttribute('download', 'voxersdk-sampleapp.apk')
        } else {
          link.setAttribute('download', 'VoxerKitDemo.zip')
        }
        document.body.appendChild(link)
        link.click()
      })
      .catch(e => {
        console.error(e)
        // TODO: show UI alert
      })
  }

  return (
    <Table className='mb-0'>
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, version, description, sdk, app, platform }) => (
          <TableRow key={id}>
            <TableCell className='pl-3 fw-normal'>{version}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>
              <Button
                // color={states[sdk.toLowerCase()]}
                disabled={platform === 'android'}
                onClick={(platform === 'android') ? downloadSDKAndroidNoOp : downloadSDKiOS}
                size='small'
                className='px-2'
                variant='contained'
                color='primary'
              >
                {sdk}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                // color={states[sdk.toLowerCase()]}
                onClick={(platform === 'android') ? downloadSampleAppAndroid : downloadSampleAppiOS}
                size='small'
                className='px-2'
                variant='contained'
                color='primary'
              >
                {app}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
