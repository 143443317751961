import React from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Paper,
  IconButton,
} from '@material-ui/core'
import { Settings as SettingIcon, } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export default function AppTable ({ data }) {
  // display object keys for labels
  let keys = null
  if (data.length > 0) {
    keys = Object.keys(data[0]).map(i => i.toUpperCase())
  }

  return (
    <React.Fragment>
      {data.map(({ objectId, name, id, secret }) => (
        <Paper
          style={{ marginTop: "16px" }}
          key={id}
        >
          <Typography
            variant='h4'
            style={{ float: "left", paddingLeft: "16px", paddingTop: "24px", paddingBottom: "8px" }}
          >
            {name}
          </Typography>
          <Typography
            variant='h5'
            // TODO: Check how to use theme (example: theme.palette.text.hint)
            style={{ float: "right", paddingRight: "16px", paddingTop: "24px", paddingBottom: "8px", color: "#FF0000" }}
          >
            {/* <Link to={`/app/settings?app_id=${id}`}>Settings</Link> */}
            <Link to={{ pathname: `/app/settings/${id}` }} >
              <IconButton
                  aria-haspopup='true'
                  aria-controls='profile-menu'
                  style={{ color: "gray" }}
                >
                <SettingIcon  />
              </IconButton>
            </Link>
          </Typography>
        <TableContainer>
          <Table className='mb-0'>
            <TableHead>
              <TableRow>
              <TableCell key={keys[0]}>APP {keys[0]}</TableCell>
              <TableCell key={keys[2]}>APP {keys[2]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={objectId}>
                <TableCell className='pl-3 fw-normal'>{id}</TableCell>
                <TableCell>{secret}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      ))}
    </React.Fragment>
  )
}
