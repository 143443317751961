import React from 'react'
import crypto from 'crypto'
import api from '../api'

var UserStateContext = React.createContext()
var UserDispatchContext = React.createContext()

function userReducer (state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true }
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false }
    case 'SIGNUP_SUCCESS':
      return { ...state, isAuthenticated: false }
    case 'SIGNUP_FAILURE':
      return { ...state, isAuthenticated: false }
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider ({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('id_token')
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState () {
  var context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch () {
  var context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signupUser, signOut }

// ###########################################################

function loginUser (dispatch, username, password, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)

  if (!!username && !!password) {
    const hashedPassword = hash(password)
    api
      .login({
        emailAddress: username,
        password: hashedPassword
      })
      .then((response) => {
        localStorage.setItem('id_token', response.data.token)
        localStorage.setItem('emailAddress', username)
        localStorage.setItem('password', hashedPassword)
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS' })

        history.push('/app/dashboard')
      })
      .catch((e) => {
        console.log('e', e)
        dispatch({ type: 'LOGIN_FAILURE' })
        setError(true)
        setIsLoading(false)
      })
  } else {
    dispatch({ type: 'LOGIN_FAILURE' })
    setError(true)
    setIsLoading(false)
  }
}

function signupUser (dispatch, firstName, lastName, username, password, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)

  if (!!firstName && !!lastName && !!username && !!password) {
    api
      .signup({
        emailAddress: username,
        password: hash(password),
        firstName,
        lastName
      })
      .then((response) => {
        loginUser(dispatch, username, password, history, setIsLoading, setError)
      })
      .catch((e) => {
        dispatch({ type: 'SIGNUP_FAILURE' })
        setError(true)
        setIsLoading(false)
      })
  } else {
    dispatch({ type: 'SIGNUP_FAILURE' })
    setError(true)
    setIsLoading(false)
  }
}

function hash (password) {
  const hash = crypto.createHash('sha256')
  return hash.update(password).digest('hex')
}

function signOut (dispatch, history) {
  localStorage.removeItem('id_token')
  localStorage.removeItem('emailAddress')
  localStorage.removeItem('password')
  dispatch({ type: 'SIGN_OUT_SUCCESS' })
  history.push('/login')
}
