import axios from 'axios'
import { EventEmitter } from 'events'
// import apis from './actions'

class VoxerDeveloperClient extends EventEmitter {
  constructor (config) {
    super()
    this._host = config.host
    this._port = config.port
  }

  init () {
    this._api = axios.create({
      baseURL: `${this._host}${this._port}/api`,
      // baseURL: 'https://router-imlumju6tq-uc.a.run.app/api',
      timeout: 5000
    })

    this._file_downloader_api = axios.create({
      baseURL: `${this._host}${this._port}/api`,
      // baseURL: 'https://router-imlumju6tq-uc.a.run.app/api',
      responseType : 'arraybuffer',
      timeout: 30000
      })
  }

  async downloadSdk (platform) {
    return this._downloadFile('/sdk/latest', { platform })
  }

  async downloadSampleApp (platform) {
    return this._downloadFile('/sdk/sampleApp', { platform })
  }

  async signup ({ firstName, lastName, emailAddress, password }) {
    return this.post('/user', { firstName, lastName, emailAddress, password })
  }

  async forgotPassword ({ emailAddress }) {
    return this.post('/forgot_password', { emailAddress })
  }

  async resetPassword ({ token, emailAddress, new_password }) {
    return this.post('/reset_password', { token, emailAddress, new_password })
  }

  async login ({ emailAddress, password }) {
    return this.post('/login', { emailAddress, password })
  }

  async createApp ({ appName, userAccount }) {
    return this.post(`/app`, { appName, userAccount })
  }

  async setSettings ({ appId , settings }) {
    return this.post(`/app/settings`, { appId, settings })
  }

  async getSettings ({ appId }) {
    return this.get(`/app/settings`, { appId })
  }

  async getApp ({ appId }) {
    return this.get(`/app`, { appId })
  }

  async getAppsForUser ({ userId }) {
    return this.get(`/user/${userId}/apps`)
  }

  async post (url, payload) {
    let result
    try {
      result = await this._api.post(url, this._tokenize(payload))
    }catch(error){
      if (this._didFailToAuthenticate(error)) {
        console.log('Failed to authenticate ' + error)
        try{
          await this._reauthenticate()
          result = await this._api.post(url, this._tokenize(payload))
        }catch(loginError){
          console.log('Operation failed ' + loginError)
        }
      }else{
        console.log('Operation failed ' + error)
      }
    }
    return result
  }

  async get (url, queryParams) {
    let queryParamsWithAuth = this._addAuthQueryParams(queryParams)
    let params = this._getURLSearchParams(queryParamsWithAuth)

    let result
    try {
      result = await this._api.get(url, { params })
    } catch (error) {
      if (this._didFailToAuthenticate(error)) {
        console.log('Failed to authenticate ' + error)
        try{
          await this._reauthenticate()
          queryParamsWithAuth = this._addAuthQueryParams(queryParams)
          params = this._getURLSearchParams(queryParamsWithAuth)
          result = await this._api.get(url, { params })
        }catch(loginError){
          console.log('Operation failed ' + loginError)
        }
      }else{
        console.log('Operation failed ' + error)
      }
    }
    return result
  }

  async _downloadFile(url, payload){
    let response
    try {
      response = await this._file_downloader_api.post(url, this._tokenize(payload))
    }catch(error){
      if (this._didFailToAuthenticate(error)) {
        console.log('Failed to authenticate ' + error)
        try{
          await this._reauthenticate()
          response = await this._file_downloader_api.post(url, this._tokenize(payload))
        }catch(loginError){
          console.log('Operation failed ' + loginError)
        }
      }else{
        console.log('Operation failed ' + error)
      }
    }
    return response
  }

  async _reauthenticate(){
    const username = localStorage.getItem('emailAddress')
    const hashedPassword = localStorage.getItem('password')
    const loginResult = await this.login({
      emailAddress: username,
      password: hashedPassword
    })
    localStorage.setItem('id_token', loginResult.data.token)
  }

  _didFailToAuthenticate (error){
    if(error != null && error !== undefined && error.response !== undefined && error.response.status === 401){
      return true
    } else {
      return false
    }
  }

  _addAuthQueryParams(queryParams){
    return Object.assign(queryParams || {}, { emailAddress: localStorage.getItem('emailAddress') } , { token: localStorage.getItem('id_token') })
  }

  _getURLSearchParams(queryParams){
    const params = new URLSearchParams();
    Object.keys(queryParams).forEach(k => {
      params.append(k, queryParams[k]);
    })
    return params
  }

  _tokenize (payload) {
    const token = localStorage.getItem('id_token')
    const emailAddress = localStorage.getItem('emailAddress')
    if (token && emailAddress) {
      return Object.assign({ emailAddress }, payload || {}, { token })
    }

    return payload
  }
}

// TODO: avoid this pattern..
//    don't want to create new client every time this is required in a file
const config = {
  host: (process.env.NODE_ENV === 'production') ? 'https://router-imlumju6tq-uc.a.run.app' : 'http://localhost',
  port: (process.env.NODE_ENV === 'production') ? '' : ':8081'
}

const client = new VoxerDeveloperClient(config)

client.init()

export default client
