import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
 span: {
    textDecoration: 'underline',
 },

 p: {
     marginLeft: '8px',
 }


}))
