import React from 'react'
import { Grid, Container } from '@material-ui/core'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

// components
import PageTitle from '../../components/PageTitle'
import Widget from '../../components/Widget'

export default function AndroidDocumentation () {

  return (
    <Container>
      <PageTitle title='Android Documentation' />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget>
            <h1>Voxer Android SDK v1.8</h1>
            <h2>Feature description</h2>
              <ol>
                <li key={1}>Every client will be provided with unique appId and appSecret. These two values will be needed to initialize voxer sdk in their apps.</li><br />
                <li key={2}>Any user can login using a unique user id. Users can the start chat with any other user as long they know the user id of the other user.</li><br />
                <li key={3}>Users can send audio and text messages. Sdk provides different callbacks to signal whether message has been successfully sent, waiting to be sent or failed to send. Users can resend the failed message after sdk signals failures to send. Every time user sends a new message or resends failed message, sdk tries multiple times in background before returning it as failed message.</li><br />
                <li key={4}>Users can receive live audio messages, pre-recorded audio messages and text messages.</li><br />
                <li key={5}><b>Partial Upload and Download Feature</b> Even if there are multiple network disconnections while recording/receiving an audio message, the message will be correctly delivered/received.</li><br />
                <li key={6}><b>Send/Receive long audio messages</b> Users can send/receive long audio messages while the user is in online or offline mode. Voxer SDK's optimization minimizes the data uploaded to the backend cloud service and the data downloaded by the client.</li><br />
                <li key={7}><b>Walkie-Talkie Feature (Play)</b> After the user starts the chat with any user by explicitly calling start conversation, any incoming live audio message will be played out loud regardless of whether app is in background or foreground. SDK will continue to play live audio outloud from there on until user explicitly calls logout.</li><br />
                <li key={8}><b>Walkie-Talkie Feature (Record)</b> Users can record an audio to a conversation using an external button. User has to mark the conversation as primary to enable this feature. At any given time only one conversation can be marked as primary. If user marks a conversation as primary while some other conversation is already marked as primary, sdk overrides the older selection with the latest selection.</li><br />
                <li key={9}><b>Audio Focus</b> Sdk respects audio focus rules of the ecosystem. It stops audio playback if user picks up a call. It lowers the volume upon loosing audio focus for brief period of time and restores the audio playback volume upon regaining the focus. It also stops audio playback upon completely loosing audio focus. Sdk only starts audio recording if no phone call is in progress.</li><br />
                <li key={10}><b>Audio playback routing</b> Sdk can route audio through bluetooth headset(supports HFP or A2DP profile), wired headset, earpiece or phone speaker. User can explicitly choose to play from either earpiece or speaker, if neither wired headset or bluetooth headset is connected.</li><br/>
                <li key={11}><b>Audio mic routing</b> Sdk can record audio from phone mic, wired headset or bluetooth headset. Sdk automically switch to wired headset or bluetooth headset if any one of them is connected. Once the user disconnects external mic, the sdk defaults to phone mic.</li><br/>
                <li key={12}><b>Group Chat</b> Users can start a group chat with one or more users. The chat creator is assigned the admin role and can rename the chat, add members, remove members and reassign the admin of the chat. All the members of the chat can view the list of members in that chat. Users can view the sender name and the timestamp of every incoming message. All the members can leave the chat, except the admin.</li><br/>
              </ol><br />
            <h2>Integrate Voxer SDK with your sample app</h2>
            <h4>In your gradle.properties file please add the following</h4>
            <pre><SyntaxHighlighter>authToken=jp_qq2f9o436j0r2f49rmv88dke9h</SyntaxHighlighter></pre>
            <h4>In your module's gradle file please add following under dependencies section</h4>
            <pre><SyntaxHighlighter>implementation 'com.github.Voxer:voxer_io_android_sdk:18af653b79'</SyntaxHighlighter></pre>
            <pre><SyntaxHighlighter>implementation 'org.jetbrains.kotlin:kotlin-stdlib:1.6.0'</SyntaxHighlighter></pre>
            <pre><SyntaxHighlighter>implementation 'androidx.core:core-ktx:1.5.0'</SyntaxHighlighter></pre>
            <pre><SyntaxHighlighter>implementation "org.jetbrains.kotlinx:kotlinx-coroutines-android:1.4.3"</SyntaxHighlighter></pre>
            <pre><SyntaxHighlighter>implementation "org.jetbrains.kotlinx:kotlinx-coroutines-core:1.4.3"</SyntaxHighlighter></pre>
            <h4>In your module's gradle file please add following under android section</h4>
            <pre><SyntaxHighlighter>{`lintOptions{ checkReleaseBuilds false}`}</SyntaxHighlighter></pre>
            <h4>In your app level gradle file please add following under repositories </h4>
            <pre><SyntaxHighlighter>{`maven { url 'https://jitpack.io' }`}</SyntaxHighlighter></pre>
            <h4> In your app level gradle file please add following under allprojects -> repositories</h4>
            <pre><SyntaxHighlighter>{`maven {
                url 'https://jitpack.io'
                credentials { username 'jp_6025ebstlo9iolugpmh8vj0ki9' }
            } `}</SyntaxHighlighter></pre>
            
            <h2>Initialize Voxer SDK</h2>
            <h4>With default feature - play audio in background enabled</h4>
            <pre><SyntaxHighlighter>Voxer.getInstance(application)</SyntaxHighlighter></pre>
            <h4>With default feature - play audio in background disabled</h4>
            <pre><SyntaxHighlighter>Voxer.getInstance(application, enablePlayAudioInBackground = false)</SyntaxHighlighter></pre>
            <p>Please provide record permission to allow Voxer to record audio</p>
            <pre><SyntaxHighlighter>uses-permission android:name="android.permission.RECORD_AUDIO"</SyntaxHighlighter></pre>
            <br />
            <h2>Log into Voxer network</h2>
            <pre>
              <SyntaxHighlighter>
                {`Voxer.getInstance(application).login (username,appId, appSecret) 
                { success ->
                  if (success) { //handle successfull login }
                  else         { //handle login failure }
                }`}
              </SyntaxHighlighter>
            </pre><br />
            <h2>Log out of Voxer network</h2>
            <pre>
              <SyntaxHighlighter>
                {'GlobalScope.launch { Voxer.getInstance(application).logout() }'}
              </SyntaxHighlighter>
            </pre><br />
            <h2>Initialize Voxer SDK and start a new conversation with the list of unique users</h2>
            <SyntaxHighlighter>voxer.startConversationWith(otherUserIds, lifecycle, conversationStarterCallback)</SyntaxHighlighter>
            <p>Here is the code snippet which. shows sample conversationStarterCallback :</p>
            <SyntaxHighlighter>
              {`override fun onConversationStarted(conversation: Conversation) {
                    lifecycleScope.launch(Dispatchers.Main) {
                            //Initialize UI
                            //Refresh List
                            //Listen for new incoming messages
                        }
                    }

                    override fun onError(e: Error) {
                      //Display appropriate message to the user
                    } `}
            </SyntaxHighlighter><br />
            <h2>Send text message on voxer network</h2>
            <SyntaxHighlighter>conversation.sendTextMessage(text, messageCallback)</SyntaxHighlighter>
            <p>Here is the code snippet which shows how to use the above api with an UI element</p>
            <SyntaxHighlighter>
              {`btnSendMessage.setOnClickListener {
                val messageBody = etStartTyping.text.toString()
                sendTextMessage(messageBody) {
                btnSendMessage.hideKeyboard()
                }
                etStartTyping.setText("")
                }`}
            </SyntaxHighlighter><br />
            <h2>Send Audio message on voxer network</h2>
            <SyntaxHighlighter>
              {`conversation.startRecording( onRecordingStarted = { // refresh the list of messages },
              messageCallbacks = MessageCallbacks())`}
            </SyntaxHighlighter>
            <p>User can stop sending audio message using the following code snippet</p>
            <SyntaxHighlighter>
              {'conversation.stopRecording()'}
            </SyntaxHighlighter><br />
            <h2>Listen to message updates</h2>
            <SyntaxHighlighter>
              {`conversation.subscribeToNewMessages { message ->
                addNewMessageToList(message)
                }`}
            </SyntaxHighlighter>
            <p>User can use following code snippet as reference when designing their list adapter :</p>
            <SyntaxHighlighter>
              {`class MessageInteractorInterfaceImpl(private val conversation: Conversation,
                                                      private val refreshMessageListCallback: (List<MessageUser>) -> Unit) : MessageInteractorInterface{
                      override fun playMessage(messageUser: MessageUser,listener: Conversation.MessagePlaybackListener) {
                          conversation.playMessage(messageUser,listener)
                      }

                      override fun pauseMessage(messageUser: MessageUser) {
                          conversation.pauseMessage(messageUser)
                      }

                      override fun removePlaybackListenerForMessage(messageUser: MessageUser) {
                          conversation.removeListenerForMessage(messageUser)
                      }

                      override fun isMessageBeingRecorded(messageUser: MessageUser): Boolean {
                          return conversation.isMessageBeingRecorded(messageUser)
                      }

                      override fun refreshMessages() {
                          conversation.getMessages { list ->
                              refreshMessageListCallback(list)
                          }
                      }
                  }`}
            </SyntaxHighlighter><br />
            <h2>Walkie-talkie feature usage</h2>
            <h4>Mark conversation as primary</h4>
            <SyntaxHighlighter>voxer.enablePrimaryWalkieTalkieMode(threadId!!)</SyntaxHighlighter>
            <h4>Disable walkie-talkie feature for a given conversation</h4>
            <SyntaxHighlighter>voxer.disablePrimaryWalkieTalkieMode(threadId)</SyntaxHighlighter>
            <h4>Start recording for primary conversation</h4>
            <SyntaxHighlighter>
              {`voxer.recordAudioForPrimaryWalkieTalkieConversation(lifecycle = this.lifecycle,
                                                audioRecorderCallbacks = getSampleAudioRecorderCallbacks(context = this),
                                                stopRecordingCallback = {
                                                        conversation ->
                                                    GlobalScope.launch(Dispatchers.Main) {
                                                        Snackbar.make(getAnchorView.invoke(),"Conversation to stop: $conversation", Snackbar.LENGTH_LONG).show()
                                                    }
                                                },
                                                messageCallbacks = SampleMessageCallback(context = this))`}
            </SyntaxHighlighter><br />
            <h2>Get list of chats</h2>
            <SyntaxHighlighter>
              {`voxer.getInstance(application).getChats(){ threadNames: List<String> ->
                //TODO: UPDATE UI
                }`}
            </SyntaxHighlighter>
            <h2>Observe list of chats</h2>
            <p>User can use following code snippet as reference to observe list of chats :</p>
            <SyntaxHighlighter>
              {`voxer.observeChatList {threadNames: List<String> ->
                threadNames: List<String> ->
                //TODO: UPDATE UI
                }`}
            </SyntaxHighlighter>
            <p>Client should stop observing the list using following call :</p>
            <SyntaxHighlighter>voxer.clearChatListObserver()</SyntaxHighlighter><br />
            <h2>Add new member to the chat</h2>
            <p>Admin of the chat can add a new member to the chat using a valid user id :</p>
            <SyntaxHighlighter>
              {`if (requestCode == AddMemberFragment.ADD_MEMBER_REQ && resultCode == Activity.RESULT_OK) {
                GlobalScope.launch(Dispatchers.Main) {
                  val bundle = data?.extras
                  val newUserId = bundle?.getString(AddMemberFragment.NEW_MEMBER_USER_ID_KEY)
                  Snackbar.make(
                      this@ChatActivity.conversationActivityId, "Added member - $newUserId", Snackbar.LENGTH_LONG
                  ).show()
                  if(!newUserId.isNullOrEmpty()) {
                    chat.addMember(userId = newUserId, AddMemberCallBackImpl())
                  }
                  }
                }`}
            </SyntaxHighlighter>
            <h2>Remove a member from the chat</h2>
            <p>Admin of the chat can remove an existing member of the chat using it's user id :</p>
            <SyntaxHighlighter>
              {`if (requestCode == RemoveMemberFragment.REMOVE_MEMBER_REQ && resultCode == Activity.RESULT_OK) {
                GlobalScope.launch(Dispatchers.Main) {
                  val bundle = data?.extras
                  val newUserId = bundle?.getString(RemoveMemberFragment.REMOVE_MEMBER_USER_ID_KEY)
                  Snackbar.make(
                      this@ChatActivity.conversationActivityId, "Removed member - $newUserId", Snackbar.LENGTH_LONG
                  ).show()
                  if(!newUserId.isNullOrEmpty()) {
                      chat.removeMember(userId = newUserId, RemoveMemberCallBackImpl())
                  }
                }
               }`}
            </SyntaxHighlighter>
            <h2>Leave a chat</h2>
            <p>Only the participant who is not an admin can leave the chat. If admin wants to leave the chat, he/she has to reassign new admin for the chat before leaving</p>
            <SyntaxHighlighter>
              {`inner class LeaveChatCallbackImpl: LeaveChatCallback {
                override fun onChatLeftSuccessfully(chatId: String) {
                    Snackbar.make(
                        this@ChatActivity.conversationActivityId, "User left this chat successfully", Snackbar.LENGTH_LONG
                    ).show()
                    refreshList()
                    hideAllSendButtons()
                    hideMessageSendingEditText()
                }
        
                override fun onError(chatId: String) {
                    Snackbar.make(
                        this@ChatActivity.conversationActivityId, "Failed to leave this chat", Snackbar.LENGTH_LONG
                    ).show()
                }
            }

            chat.leaveChat(LeaveChatCallbackImpl())`}
            </SyntaxHighlighter>
            <h2>Reassign the admin of a chat</h2>
            <p>Admin of the chat can reassign any other user as the admin of the chat</p>
            <SyntaxHighlighter>
              {`inner class ReassignAdminCallbackImpl: ReAssignAdminCallback{
                      override fun onAdminReassignedSuccessfully(newAdminUserId: String, chatId: String) {
                             Snackbar.make(
                             this@ChatActivity.conversationActivityId, "$newAdminUserId successfully assigned as new admin of this chat", Snackbar.LENGTH_LONG
                             ).show()
                             refreshList()
                       }

                      override fun onError(newAdminUserId: String, chatId: String) {
                            Snackbar.make(
                            this@ChatActivity.conversationActivityId, "Unable to assign $newAdminUserId as a new admin", Snackbar.LENGTH_LONG
                            ).show()
                 }
               }

               chat.reassignAdminChat(newAdminUserId, ReassignAdminCallbackImpl())`}
            </SyntaxHighlighter>
            <h2>Rename a chat</h2>
            <p>Admin of the chat can change the name of the chat. All other users will notified via system message once admin performs the operation.</p>
            <SyntaxHighlighter>
              {`inner class RenameChatFragmentCallbackImpl: RenameChatCallback {
                override fun onChatRenamedSuccessfully(chatId: String, newName: String, oldName: String) {
                    Snackbar.make(
                        this@ChatActivity.conversationActivityId, "Chat renamed to $newName successfully", Snackbar.LENGTH_LONG
                    ).show()
                    refreshList()
                    conversationName.postValue(newName)
                }
        
                override fun onError(chatId: String) {
                    Snackbar.make(
                        this@ChatActivity.conversationActivityId, "Failed to change name", Snackbar.LENGTH_LONG
                    ).show()
                    refreshList()
                }
              }

               chat.renameChat(newChatName, RenameChatFragmentCallbackImpl())`}
            </SyntaxHighlighter>
            <h2>APIs</h2>
            <p>Description of the APIs showcased above can be found&nbsp;
              <a
                href='https://developers.voxer.io/android/docs'
                target='_blank'
              >here
              </a>
              .
            </p>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  )
}
