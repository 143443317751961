import React from 'react'
import { Grid, Container } from '@material-ui/core'

// components
import PageTitle from '../../components/PageTitle'
import Widget from '../../components/Widget'
import TableComponent from '../dashboard/components/Table/TableComponent'

// data
import mock from '../dashboard/mock'

export default function Download () {
  return (
    <>
    <Container>
      <PageTitle title='Download SDK' />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title='Android SDK' upperTitle noBodyPadding>
            <TableComponent data={mock.android} />
          </Widget>
        </Grid>
      </Grid>
    </Container>
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title='iOS SDK' upperTitle noBodyPadding>
            <TableComponent data={mock.iOS} />
          </Widget>
        </Grid>
      </Grid>
    </Container>
  </>
  )
}
