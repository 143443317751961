import React from 'react'
import {
  Route,
  Switch,
  withRouter
} from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'

// pages
import Dashboard from '../../pages/dashboard'
import Download from '../../pages/download'
import AndroidDocumentation from '../../pages/android'
import iOSDocumentation from '../../pages/ios'
import Settings from '../../pages/settings'

// context
import { useLayoutState } from '../../context/LayoutContext'

function Layout (props) {
  var classes = useStyles()

  // global
  var layoutState = useLayoutState()

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path='/app/dashboard' component={Dashboard} />
            <Route path='/app/download' component={Download} />
            <Route path='/app/android' component={AndroidDocumentation} />
            <Route path='/app/ios' component={iOSDocumentation} />
            <Route path='/app/settings/:appId' component={Settings} />
          </Switch>
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
