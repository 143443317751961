import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Paper
} from '@material-ui/core'
import {
  withRouter, 
  Link
} from 'react-router-dom'

// styles
import useStyles from './styles'

// logo
import logo from './logo.svg'

// logo
import audio from './audio.png'

// context
import { useUserDispatch, loginUser, signupUser } from '../../context/UserContext'
// import validate from "./components/validateLogin";


// from npm emailValidator
const emailValidatorPattern = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/ // eslint-disable-line

function Login (props) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  })

  const onSubmitLogin = (data) => {
    data.password = '*****'
  }

  const onSubmitSignup = (data) => {
    data.password = '*****'
  }

  // Recaptcha v2
  function onChange(value) {
    setEnabled(!disabled);
  }

  const [disabled, setEnabled] = useState('disabled');

  var classes = useStyles()

  // global
  var userDispatch = useUserDispatch()

  // local
  var [isLoading, setIsLoading] = useState(false)
  var [error, setError] = useState('')
  // var [emailError, setEmailError] = useState("");
  // var [passwordError, setPasswordError] = useState("");
  var [activeTabId, setActiveTabId] = useState(0)
  var [firstNameValue, setFirstNameValue] = useState('')
  var [lastNameValue, setLastNameValue] = useState('')
  var [loginValue, setLoginValue] = useState('')
  var [passwordValue, setPasswordValue] = useState('')

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt='logo' className={classes.logotypeImage} />
        <h3 style={{ maxWidth: '560px', textAlign: 'center', marginTop: '0px', marginBottom: '0px' }}>Use the Voxer SDK and integrate real-time PTT communication into your application or service.</h3>
        <div style={{ maxWidth: '700px', display: 'flex', marginTop: '2%' }}>
          <img src={audio} className={classes.audiotypeImage} alt='voxer-audio' />
          <Typography className={classes.textContainer}>
            <ul>
              <li>
                <h4 className={classes.titleText}>Walkie-Talkie Like Audio</h4>
              </li>
              <li>
                <h4 className={classes.titleText}>Text Messaging</h4>
              </li>
              <li>
                <h4 className={classes.titleText}>Team Communication</h4>
              </li>
            </ul>
          </Typography>
        </div>
        <Typography>
          <h3 style={{ marginTop: '0', marginBottom: '4px' }}>Pricing for teams of all sizes</h3>
        </Typography>
        <div style={{ display: 'flex', maxWidth: 600 }}>
          <Paper className={classes.pricingBox}>
            <h3 className={classes.pricingHeader}>Beta Program</h3>
            <p>Free for up to 50 monthly active users</p>
          </Paper>
          <Paper className={classes.pricingBox}>
            <h3 className={classes.pricingHeader}>Enterprise</h3>
            <p>Contact us for pricing</p>
          </Paper>
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab label='Sign Up' classes={{ root: classes.tab }} />
            <Tab label='Login' classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 1 && (
            <>
              <Typography variant='h2' className={classes.greeting}>
                Voxer SDK Login
              </Typography>
              <Fade in={error}>
                <Typography color='secondary' className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>

              <form onSubmit={handleSubmit(onSubmitLogin)}>
                <TextField
                  id='email'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={loginValue}
                  onChange={e => { setLoginValue(e.target.value) }}
                  margin='normal'
                  placeholder='Email Address'
                  type='email'
                  fullWidth
                  helperText={errors.email && 'Please provide a valid email'}
                  name='email'
                  inputRef={register({ pattern: emailValidatorPattern })}
                />
                <TextField
                  id='password'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={passwordValue}
                  onChange={e => { setPasswordValue(e.target.value) }}
                  margin='normal'
                  placeholder='Password'
                  type='password'
                  fullWidth
                  helperText={errors.password && 'Password must be 8 characters'}
                  name='password'
                  inputRef={register({ required: true, minLength: 8 })}
                />
                <div className={classes.creatingButtonContainer}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={
                        errors.email || errors.password || loginValue.length === 0 || passwordValue.length === 0
                      }
                      onClick={() => {
                        loginUser(
                          userDispatch,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError
                        )
                      }}
                      size='large'
                      variant='contained'
                      color='primary'
                      type='submit'
                      fullWidth
                      className={classes.createAccountButton}
                    >
                    Login
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    size='large'
                    color='primary'
                    fullWidth
                    className={classes.forgetButton}
                    component={Link}
                    to='/forgot'
                  >
                    Forgot Password?
                  </Button>
                </div>
              </form>
            </>
          )}
          {activeTabId === 0 && (
            <>
              <Typography variant='h2' className={classes.greeting}>
                Voxer SDK
                <br />
                Create your account
              </Typography>
              <Fade in={error}>
                <Typography color='secondary' className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <form onSubmit={handleSubmit(onSubmitSignup)}>
                <div className={classes.signupDisplay}>
                  <TextField
                    className={classes.signupInput}
                    id='firstname'
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField
                      }
                    }}
                    value={firstNameValue}
                    onChange={e => setFirstNameValue(e.target.value)}
                    margin='normal'
                    placeholder='First Name'
                    type='text'
                    width='40%'
                  />
                  <TextField
                    className={classes.signupInput}
                    id='lastname'
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField
                      }
                    }}
                    value={lastNameValue}
                    onChange={e => setLastNameValue(e.target.value)}
                    margin='normal'
                    placeholder='Last Name'
                    type='text'
                    width='40%'
                  />
                </div>
                <TextField
                  required
                  id='email'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={loginValue}
                  onChange={e => setLoginValue(e.target.value)}
                  margin='normal'
                  placeholder='Email Address'
                  type='email'
                  fullWidth
                  helperText={errors.email && 'Please provide a valid email'}
                  name='email'
                  inputRef={register({ pattern: emailValidatorPattern })}
                />
                <TextField
                  id='password'
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField
                    }
                  }}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  margin='normal'
                  placeholder='Password'
                  type='password'
                  helperText={errors.password && 'Password must be 8 characters'}
                  fullWidth
                  name='password'
                  inputRef={register({ required: true, minLength: 8 })}
                />
                <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                  <Typography className={classes.formDivider} />
                  <div className={classes.formDivider} />
                </div>
                    <ReCAPTCHA
                      sitekey="6LeF__wUAAAAAGnAxSPnR0skdrLCDpkFhotcN5v9"
                      onChange={onChange}
                      style={{
                        marginTop: '8px',
                        marginBottom: '-8px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'relative'
                      }}
                      render="explicit"
                    />
                    <Typography className={classes.root} style={{
                      textAlign: "center",
                      paddingTop: "8%",
                      }}>
                      By creating an account I agree to Voxer's<br/>&nbsp;
                      <Link
                        className={classes.link}
                        to='/tos'
                        target='_blank'
                        >Terms of Service
                      </Link>
                    </Typography>
                <div className={classes.creatingButtonContainer}>
                  {isLoading ? (
                    <CircularProgress size={26} />
                  ) : (
                    <Button
                      onClick={() =>
                        signupUser(
                          userDispatch,
                          firstNameValue,
                          lastNameValue,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError
                        )}
                      disabled={disabled}
                        // loginValue.length === 0 ||
                        // passwordValue.length === 0 ||
                        // firstNameValue.length === 0 ||
                        // lastNameValue.length === 0 ||
                        // errors.email ||
                        // errors.password ||
                      size='large'
                      variant='contained'
                      color='primary'
                      fullWidth
                      className={classes.createAccountButton}
                      id='signupbtn'
                    >
                    Create your account
                    </Button>
                  )}
                </div>
                
              </form>
            </>
          )}
        </div>
      </div>
    </Grid>
  )
}

export default withRouter(Login)
