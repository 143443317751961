import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'f5f5f5',
    position: 'absolute',
    top: 0,
    left: 0
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logotypeText: {
    fontWeight: 500,
    color: 'white',
    marginLeft: theme.spacing(2)
  },
  logotypeIcon: {
    width: 200,
    marginRight: theme.spacing(2)
  },
  paperRoot: {
    boxShadow: theme.customShadows.widgetDark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    maxWidth: 404
  },
  textRow: {
    marginBottom: theme.spacing(10),
    textAlign: 'center'
  },
  errorCode: {
    fontSize: '38px',
    fontWeight: 500
  },
  link: {
    color: theme.palette.primary.main,
    marginTop: '5%',
    textAlign: 'center',
  },
  backButton: {
    boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    fontSize: 22
  },
  greeting: {
    textAlign: 'center',
  },
  form: {
    marginTop: '5%',
  },
}))
